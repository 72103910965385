const Header = {
    init() {
        window.addEventListener('scroll', this.onScroll);
        document.addEventListener('alpine:init', () => {
            Alpine.data('NavigationMenu', () => Header.navigationMenu);
        });
        document.addEventListener('alpine:init', () => {
            Alpine.data('NavidationMobileMenu', () => Header.navidationMobileMenu);
        });
        Header.scrollSpy();
    },
    onScroll() {
        const navigation = document.querySelector('#navigation');
        if (!navigation) {
            return;
        }
        var scroll_distance = window.top.scrollY;
        scroll_distance != 0 ? navigation.classList.add('scrolling') : navigation.classList.remove('scrolling');
    },
    scrollSpy() {
        const anchor_navigation = document.querySelector('#anchor-navigation');
        if (!anchor_navigation) {
            return;
        }
        const anchor_navigation_links = anchor_navigation.querySelectorAll('a:not(.btn)');
        const sections = document.querySelectorAll('section[id][class*="wp-block-"], div[id].wp-block-group');

        let current_active_section = null; // Keep track of the currently active section

        document.addEventListener('scroll', () => {
            const scroll_y = window.scrollY;
            const sticky_navbar_offset = anchor_navigation.getBoundingClientRect().bottom; // Dynamic offset from the top
            const effective_offset = Math.max(0, sticky_navbar_offset); // Ensure it's not negative
            let new_active_section = null;

            sections.forEach((section) => {
                const section_id = section.id;
                const section_top = section.offsetTop - effective_offset; // Adjust for dynamic offset
                const section_height = section.offsetHeight;

                // Check if the current scroll position is within the section
                if (scroll_y >= section_top && scroll_y < section_top + section_height) {
                    new_active_section = section_id; // Mark this section as active
                }
            });

            // Update classes only if the active section has changed
            if (new_active_section !== current_active_section) {
                current_active_section = new_active_section;

                anchor_navigation_links.forEach((link) => {
                    if (new_active_section && link.hash === '#' + new_active_section) {
                        link.classList.add('text-primary-1');
                    } else {
                        link.classList.remove('text-primary-1');
                    }
                });
            }
        });
    },
    navigationMenu: {
        init() {
            const nav = this.$el;
            const backdrop = document.querySelector('.dropdown-backdrop');
            const header = document.querySelector('header');
            const nav_list = document.querySelector('header .nav-list');
            const container_width = window.innerWidth > 1280 ? 1280 : 1024;
            const nav_items = nav.querySelectorAll('.nav-item a');
            const dropdown_items = nav.querySelectorAll('[class*="dropdown-"]');
            let currentDropwdown = null;

            nav_items.forEach((item) => {
                if (item.dataset.children === 'false') {
                    return;
                }
                // Mouseover event
                item.addEventListener('mouseover', (event) => {
                    currentDropwdown = event.target.dataset.navItem;
                    var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);

                    if (dropdownItem) {
                        Header.navigationMenu.showDropdown(dropdownItem, dropdown_items, backdrop);
                        var dropdownItemHeight = dropdownItem.offsetHeight + 4; // 4px is the border width
                        var dropdownItemWidth = dropdownItem.offsetWidth + 4; // 4px is the border width

                        backdrop.style.height = dropdownItemHeight + 'px';
                        backdrop.style.width = container_width > dropdownItemWidth ? dropdownItemWidth + 'px' : container_width + 'px';

                        if (container_width < dropdownItemWidth) {
                            dropdownItem.style.setProperty('width', container_width + 'px', 'important');
                        }
                    }
                });
                // Mouseleave event
                item.addEventListener('mouseleave', (event) => {
                    if (header.contains(event.relatedTarget) && !nav_list.contains(event.relatedTarget)) {
                        return;
                    }
                    var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);
                    Header.navigationMenu.hideDropdown(dropdownItem, dropdown_items, backdrop);
                });
            });
            // Mouseleave event on backdrop
            header.addEventListener('mouseleave', (event) => {
                if (header.contains(event.relatedTarget) && !nav_list.contains(event.relatedTarget)) {
                    return;
                }
                var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);
                Header.navigationMenu.hideDropdown(dropdownItem, dropdown_items, backdrop);
            });
            backdrop.addEventListener('mouseleave', (event) => {
                if (header.contains(event.relatedTarget) && !nav_list.contains(event.relatedTarget)) {
                    return;
                }
                var dropdownItem = nav.querySelector('.dropdown-' + currentDropwdown);
                Header.navigationMenu.hideDropdown(dropdownItem, dropdown_items, backdrop);
            });
        },
        showDropdown(dropdownItem, dropdown_items, backdrop) {
            if (!dropdownItem || !backdrop) {
                return;
            }
            // Make sure all dropdowns are hidden before showing the current dropdown
            dropdown_items.forEach((element) => {
                if (element.classList.contains('show-dropdown')) {
                    element.classList.remove('show-dropdown');
                    element.classList.add('hide-dropdown');
                }
            });
            // Show the current dropdown
            dropdownItem.classList.add('show-dropdown');
            dropdownItem.classList.remove('hide-dropdown');
            backdrop.classList.add('show-dropdown-backdrop');
            backdrop.classList.remove('hide-dropdown-backdrop');
        },
        hideDropdown(dropdownItem, dropdown_items, backdrop) {
            if (!dropdownItem || !backdrop) {
                return;
            }
            // Make sure all dropdowns are hidden
            dropdown_items.forEach((element) => {
                if (element.classList.contains('show-dropdown')) {
                    element.classList.remove('show-dropdown');
                    element.classList.add('hide-dropdown');
                }
            });
            backdrop.classList.remove('show-dropdown-backdrop');
            backdrop.classList.add('hide-dropdown-backdrop');
        },
    },
    navidationMobileMenu: {
        show_mobile_menu: false,
        mobile_menu_wrapper: null,
        mobile_menu_open_button: null,
        mobile_menu: null,

        init() {
            this.mobile_menu_wrapper = this.$el;
            this.mobile_menu_open_button = document.querySelector('#mobile-menu-open-button');
            this.mobile_menu = this.mobile_menu_wrapper.querySelector('.mobile-menu');

            // Open mobile menu
            this.mobile_menu_open_button.addEventListener('click', (event) => {
                this.checkIfMobileMenuIsOpen();
            });
        },
        checkIfMobileMenuIsOpen() {
            if (this.show_mobile_menu) {
                this.mobile_menu.classList.remove('show-mobile-menu');
                this.mobile_menu.classList.add('hide-mobile-menu');
                this.show_mobile_menu = false;
                document.body.style.overflow = 'auto';
                this.mobile_menu_open_button.children[0].classList.remove('opened');
            } else {
                this.mobile_menu.classList.add('show-mobile-menu');
                this.mobile_menu.classList.remove('hide-mobile-menu');
                this.show_mobile_menu = true;
                document.body.style.overflow = 'hidden';
                this.mobile_menu_open_button.children[0].classList.add('opened');
            }
        },
    },
};

export default Header;
