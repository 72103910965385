const Timeline = {
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('Timeline', () => Timeline.checkOverlap);
        });
    },
    checkOverlap() {
        var sections = this.$el.querySelectorAll('section.timeline-item');
        var floating_label = this.$el.querySelector('.floating-label');
        let floating_label_wrapper = this.$el.querySelector('.floating-label-wrapper');
        var condensed_layout = this.$el.classList.contains('condensed-layout');
        var one_sided_layout = this.$el.classList.contains('one-sided-layout');
        var hide_last_item_layout = this.$el.classList.contains('hide-last-item-layout');
        var last_section_value = this.$el.querySelector('section.timeline-last-item .timestamp');
        var sections_heights = [];
        sections.forEach((element) => {
            var timestamp = element.querySelector('.timestamp');
            sections_heights.push({
                element: element,
                value: timestamp ? element.querySelector('.timestamp').dataset.label : null,
                video: element.querySelector('video') || null,
                wistia: null,
                height: element.offsetHeight,
                top: element.offsetTop,
            });
        });

        document.addEventListener('scroll', function () {
            let start = condensed_layout ? 500 : 430;
            start = one_sided_layout ? 475 : start;
            start = one_sided_layout && condensed_layout ? 500 : start;
            let padding = condensed_layout ? 48 : 96;

            console.log(start);

            sections_heights.forEach((section, index) => {
                let rect = section.element.getBoundingClientRect();
                let top = rect.top;
                let end = 500 - rect.height - padding; // 500 = floating label top position and 96 = section padding

                if (top >= end && top <= start) {
                    if (floating_label && section.value) {
                        floating_label.innerHTML = section.value;
                    }
                    if (!section.element.classList.contains('active')) {
                        section.element.classList.add('active');
                    }
                    if (section.video && section.video.paused) {
                        section.video.play();
                    }
                    if (section.wistia) {
                        section.wistia.play();
                    }
                }
            });

            // When the last section is reached, set the floating label to the last section value
            if (floating_label.getBoundingClientRect().top < 500) {
                if (last_section_value) {
                    floating_label.innerHTML = last_section_value.dataset.label;
                }
            }
        });

        if (hide_last_item_layout && floating_label_wrapper) {
            let last_section = sections_heights[sections_heights.length - 1];
            let offset_height = last_section.height - 20;

            floating_label_wrapper.style.setProperty('height', `calc(100% - ${offset_height}px)`, 'important');
        }

        var checkIfWistiaIsloaded = setInterval(function () {
            if (window.Wistia) {
                clearInterval(checkIfWistiaIsloaded);
                sections_heights.forEach((section, index) => {
                    var wistia_element = section.element.querySelector('.wistia_embed');

                    if (wistia_element) {
                        sections_heights[index].wistia = Wistia.api(wistia_element.id);
                    }
                });
            }
        }, 500);
    },
};

export default Timeline;
